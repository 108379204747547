@import '../base/settings';
.importantDiv {
    min-height: 100px;
    height: unset;
    width: 100%;
    background-color: #d4edf8;
    border-radius: 8px;
    padding: 10px 0;
}

.importantDivRotate {
    min-height: 100px;
    height: unset;
    width: 100%;
    background-color: #d4edf8;
    border-radius: 8px;
    padding: 10px 0;
}