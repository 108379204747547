.info-box {
    background: rgba(168, 226, 253, 0.5);
    padding: 10px;
    border-radius: 8px;
    color: #515151;
    font-family: Roboto;
    color: #515151;
    a {
        color: #082f57;
    }
}
