.mainDivStepper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;
    width: 80%;
    .containerD {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2%;
        .container-box {
            display: inline-flex;
            .box {
                //transition: 0.3s all;
                display: inline-flex;
                justify-content: center;
                height: 246px;
                width: 336px;
                margin-right: 45px;
                border-radius: 8px;
                box-shadow: 0 1px 8px 2px rgba(0, 0, 0, 0.2);
                outline: none;
                &:hover {
                    //height: 250px;
                    //box-shadow: 0 1px 20px 4px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    cursor: hand;
                }
            }
            .label {
                display: flex;
                margin-top: 20px;
                justify-content: center;
            }
        }
    }
    .divStepper {
        width: 50%;
        margin-left: 0%;
    }
    .boxDniPictureOut {
        border: 1px solid #b6b6b6;
        border-radius: 10px;
        height: 325px;
        width: 570px;
        display: flex;
        justify-content: center;
        align-items: center;
        .boxDniPictureIn {
            border: 1px solid black;
            border-radius: 10px;
            background-color: black;
            width: 97%;
            height: 96%;
            overflow: hidden;
        }
    }
}

.active {
    border: 4px solid #00a1da;
}
.card-dni{
    margin: 0 5px 10px;
}
.card-dni.active {
    background-color: #092f57;
}
.card-dni.active p {
    color: white !important;
}
.card-content-dni{
    padding: 0;
    display: flex !important;
    flex-direction: column!important ;
    justify-content: cente !important;
    align-items: center !important;
}
.dni-example{
    display: block;
    width: 100%;
    height: 100% !important;
}

@media (max-width: 600px) {
    .emptyButton {
        position: fixed;
        bottom: 0px;
        right: 0px;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        background-color: #FFFFFF;
    }
}

.nextButton {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #d6d6d6;
    min-height: 54px;
}

@media (max-width: 600px) {
    .boxDni-portrait-ml {
        margin-left: 30px;
    }
}

.boxDni {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    border-radius: 12px;
    box-shadow: 0 1px 8px 2px rgba(0, 0, 0, 0.2);
    outline: none;
    &:hover {
        cursor: pointer;
        cursor: hand;
    }
}

.boxDni2 {
    background-color: #092f57;
    display: flex;
    justify-content: center;
    height: 180px;
    width: 260px;
    margin-top: 10px;
    border-radius: 12px;
    box-shadow: 0 1px 8px 2px rgba(0, 0, 0, 0.2);
    outline: none;
    &:hover {
        cursor: pointer;
        cursor: hand;
    }
}