@import '../base/settings';

@media (min-width: 600px) {
    .footerdiv {
        margin-top: 3%;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            'tna    tea    cft null null '
            'terms terms terms terms terms ';

        .tna {
            grid-area: tna;
        }
        .tea {
            grid-area: tea;
        }
        .cft {
            grid-area: cft;
        }
        .terms {
            grid-area: terms;
        }
    }
}

@media (max-width: 600px) {
    .footerdiv {
        width: 90%;
        margin-left: 4%;
        margin-top: $marginTop;
        margin-bottom: 70px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas:
            'tna    tea    cft '
            'terms terms terms ';

        .tna {
            grid-area: tna;
        }
        .tea {
            grid-area: tea;
        }
        .cft {
            grid-area: cft;
        }
        .terms {
            margin-top: 20px;
            grid-area: terms;
        }
    }
}
