@import '../base/settings';
@import '../base/fonts';
@media (min-width: 600px) {
    .SliderMobile {
        opacity: 0;
    }
    .Slider {
        // max-width: 50%;
        margin-left: 2%;
        width: 100px;
        height: 150px;
        margin-top: 2%;
        .boxSlider {
            display: flex;
            height: 150px;
            width: 100%;
            outline: none;
            .boxSliderEffects {
                //border:2px solid red;
                transition: 0.3s all;
                text-align: center;
                padding: 8%;
                // margin-top: 20%;
                margin: 5%;
                height: 80px;
                box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.12);
                background-color: #ffffff;
                border-radius: 10px;
                transition: 0.3s all;
                outline: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:hover {
                    background-color: #cbeaf8;
                    box-shadow: 0 4px 15px 2px rgba(0, 0, 0, 0.15);
                    height: 85px;
                    cursor: pointer;
                    cursor: hand;
                }
                &:focus {
                    background-color: #dcf4ff;
                    outline: none;
                }
                &.selected {
                    background-color: #dcf4ff;
                }
            }
        }
    }
    .customSlider {
        display: flex;
        width: 100%;
        .sideMount {
            margin-left: 30px;
            margin-top: -1%;
        }
    }
    .slick-prev {
        left: 3% !important;
        z-index: 1;
    }
    .slick-next {
        right: 3% !important;
        z-index: 2;
    }
    .importantDiv {
        width: 100%;
        height: 140px;
        .logoAdm {
            visibility: visible;
        }
    }
}

$insideMarginLeft: 6%;
$insideMarginRight: 6%;
@media (max-width: 600px) {
    .Slider {
        visibility: hidden;
    }
    .SliderMobile {
        margin-top: 5%;
        //width: 90%;
        margin-bottom: 20px;
        .boxSlider {
            display: flex;
            flex-direction: column;
            height: 120px;
            width: 50%;
            outline: none;
            .boxSliderEffects {
                transition: 0.3s all;
                text-align: center;
                padding-top: 10%;
                margin-top: 20%;
                margin: 5%;
                height: 80px;
                box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.06);
                background-color: #ffffff;
                border-radius: 10px;
                transition: 0.3s all;
                outline: none;
                &:hover {
                    background-color: #cbeaf8;
                    box-shadow: 0 4px 15px 2px rgba(0, 0, 0, 0.15);
                    //height: 85px;
                    cursor: pointer;
                    cursor: hand;
                }
                &:focus {
                    background-color: #dcf4ff;
                    outline: none;
                }
                &.selected {
                    background-color: #dcf4ff;
                }
            }
        }
    }
    .sideMount {
        text-align: center;
        width: 90%;
        justify-content: center;
    }
    .customSlider {
        width: 100%;
    }
    .importantDiv {
        width: 95%;
        height: 150px;
        .logoAdm {
            //position: absolute;
            opacity: 0;
        }
    }
}

.arrowLeft {
    cursor: pointer;
    position: absolute;
    margin-top: -6%;
    margin-left: 5px;
    width: 20px;
}

.arrowRight {
    cursor: pointer;
    z-index: 99;
    position: absolute;
    margin-top: -6.5%;
    margin-left: -22px;
    width: 20px;
}

.checkboxLabelContainer{
    min-width: 100px !important;
    margin-left: 30px;
}
.thumbChecked{
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px 0;
}
.thumbUnchecked{
    font-size: 16px;
    color: #9b9b9b;
    padding: 0 10px 0;
}

@media (max-width: 992px){
    .checkboxLabelContainer{
        width: 100% !important;
        margin-left: unset;
    }
}

@media (min-width: 1300px) and (max-width: 1600px) {
    .CustomSizeOffer {
        width: 55vw;
    }
}

@media (min-width: 600px) and (max-width: 1300px) {
    .CustomSizeOffer {
        width: 85vw;
    }
}

@media (min-width: 1601px) {
    .CustomSizeOffer {
        width: 950px;
    }
}