.btn-v1 {
    border-radius: 8px;
    outline: none;
    border: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    background-color: $primary;
    color: $button_text_v1;
    height: 30px;
    transition: 0.4s all;
    cursor: pointer;
    &:hover {
        box-shadow:
            0 5px 18px rgba(65, 64, 64, 0.25),
            0 10px 10px rgba(104, 104, 104, 0.22);
        background-color: $button_hover_v1;
    }
    &:active {
        transition: 0.1s;
        background-color: $button_active_v1;
    }
}

.startButton {
    position: unset;
    bottom: unset;
    right: unset;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: unset;
    min-height: unset;
}
.fixed-bottom-padding {
    padding-top: 20px;
    padding-bottom: 40px;
}

.recaptcha-custom {
    .grecaptcha-badge {
        z-index: 300;
    }
}

@media (min-width: 600px) {
    .buttonNext {
        width: 600px;
    }
}

@media (max-width: 600px) {
    .fixedBottom {
        position: fixed;
        bottom: 70px;
        right: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 50px;
        align-items: center;
    }
    .fixedBottomOnlyMobile {
        position: fixed;
        bottom: 60px;
        right: 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50px;
        margin-right: 20px !important;
    }
    .startButton {
        position: fixed;
        bottom: 0px;
        right: 0px;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #d6d6d6;
        min-height: 54px;
    }
    .fixed-bottom-padding {
        padding: 0;
    }
}

@media (max-width: 400px) {
    .fixedBottomOnlyMobile {
        margin-right: 15px !important;
    }
}
