@import '../base/settings';

.formEntryLabel {
    font-size: 16px;
    font-weight: 500;
    color: $primary;
    margin-bottom: 10px;
    text-align: left;
}

.nextFormButton {
    height: 45px !important;
    min-width: 100% !important;
    margin: 10px 26px 0px 10px !important;
}

.submitFormButton {
    height: 45px !important;
    min-width: 90% !important;
    max-width: 100% !important;
    margin-left: 0px !important;
    margin-top: 10px !important;
}

@media (max-width: 600px) {
    .none {
        display: none;
    }

    .fixedTermsMobile {
        position: fixed;
        bottom: 100px;
        right: 0px;
        display: flex;
        flex-direction: column;
    }

    .nextFormButton {
        height: auto !important;
        min-width: 90% !important;
        margin: 20px 0px !important;
    }

    .nextFormButtonMobile {
        height: auto !important;
        min-width: 90% !important;
        margin: 20px 0px !important;
    }

    .submitFormButton {
        height: auto !important;
        margin-left: 30px !important;
        margin-top: 20px !important;
    }
}
