.messageError-bg {
    background-color: #ffd7d7;
    border-radius: 10px;
    padding: 10px;
    width: 80%;
}

.simulationPopupCOntainer {
    position: unset;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    z-index: 100;
}
.simulationPopup {
    background-color: #07a3db;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    align-items: center;
    padding: 15px 20px;
    justify-content: center;
    position: relative;
    max-width: 400px;

    .simulationPopupButton {
        display: none;
    }
    p {
        color: white;
        font-weight: 500;
        font-size: 14px;
    }
}
.arrow-down {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #07a3db;
}

.offerSimulationAsset {
    height: 271px;
    width: 282px;
    background-image: url('../../assets/mesa.svg');
    background-repeat: no-repeat;
    background-position: center;
}

@media (max-width: 992px) {
    .messageError-bg {
        width: unset;
    }
    .simulationPopupCOntainer {
        position: fixed;
        bottom: 50px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        z-index: 100;
    }
    .simulationPopup {
        .simulationPopupButton {
            display: block;
            position: absolute;
            top: -15px;
            right: 0;
            width: 35px;
            height: 35px;
            cursor: pointer;
            background-color: white;
            border: 4px solid #07a3db;
            border-radius: 100%;
            font-size: 20px;
        }
    }
}
@media (max-width: 667px) {
    .simulationPopup {
        max-width: 80%;
    }
}

@media (max-width: 400px) {
    .simulationPopup p {
        font-size: 13px;
    }
}
