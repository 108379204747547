@import '../base/settings';

@media (min-width: 600px) {
    .questionContainer {
        display: flex;
        justify-content: right;
    }
}

$insideMarginLeft: 6%;
$insideMarginRight: 6%;

@media (max-width: 600px) {
    .centraldivIdentify {
        //border: 2px solid rgb(221, 4, 4);
        display: flex;
        flex-direction: column;
        background-color: #f7f7f7;
        max-width: 100vh;
        height: 100vh;
        overflow: hidden;

        .titleIdentify {
            // position: fixed;
            display: inline-flex;
            width: 100%;
            height: 6.5%;
            background-color: $primary;

            .logoIdentify {
                height: 65%;
                margin-left: $insideMarginLeft;
                margin-top: 2%;
            }
            .help {
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                color: white;
                border: 1px solid white;
                border-radius: 5px;
                width: 85px;
                height: 22px;
                margin-left: 20%;
                margin-top: 4%;
                //position: fixed;
                //right: 6%;
                // top: 1.6%;
            }
        }
        .imgcontentIdentify {
            margin-top: 12%;
            position: absolute;
            margin-left: auto;
            height: 100vh;
            padding-left: $insideMarginLeft;
            padding-right: $insideMarginRight;
            .subTitleIdentify {
                font-weight: 500;
                font-size: 30px;
                color: $primary;
            }
        }

        .imgdivIdentify {
            visibility: hidden;
        }
    }
}

.formQuestionsIdentify {
    display: flex;
    flex-direction: column;
    height: 200px;
    //border: 2px solid red;
    width: 100%;
}
.nextButton {
    position: fixed;
    // border: 2px solid red;
}
