@import '../base/settings';

.centraldiv {
    .imgcontent {
        grid-area: imgcontent;
        display: flex;
        flex-direction: column;
        color: $title_text;
        .tabscontainer {
            height: 500px;
            width: 700px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

.cuitDescription {
    display: flex;
    flex-direction: column;
    align-content: left;
    background-color: #f0f0f0;
    border-radius: 0 0 10px 10px;
    margin-top: 0px;
    padding: 10px;
    padding-left: 20px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
    .separator {
        background-color: #e5e5e5;
        height: 1px;
        width: 90%;
    }
}
.select-cuit-desc-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btnLogin {
    display: flex;
    justify-items: flex-end;
    justify-content: flex-end;
    align-self: flex-end;
    height: 50%;
    min-width: 110px;
}

.react-code-input input {
    padding: 0 !important;
    text-align: center;
}

.helperTextPopupBox {
    width: 100%;
    margin: 30px 0 10px;
}
.helpTextPopup {
    color: #53bfe6 !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
    text-transform: none !important;
    text-decoration: underline !important;
}

.subtitleLogin {
    font-size: 32px;
    font-weight: 500;
    color: $primary;
    margin: 15px 0px 10px 0px;
}

@media (min-width: 768px) {
    .footer {
        text-align: left;
        bottom: 0;
        margin: 16px 0px !important;
        width: 40%;
        z-index: 200;
        font-size: 10px;
        line-height: 18.75px;
    }
}
@media (max-width: 960px) {
    .footer {
        width: 100%;
    }
}
@media (max-width: 768px) {
    .footer {
        display: block;
        text-align: center;
        position: fixed;
        margin-bottom: 12px !important;
        bottom: 0;
        width: 100%;
        font-size: 10px;
    }
    .subtitleLogin {
        font-size: 24px;
    }
}
