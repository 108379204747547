@import './../../base/settings';

@media (min-width: 600px) {
    .centraldiv {
        display: grid;
        grid-template-columns: 40% 60%;
        grid-template-areas:
            ' title   title   '
            'imgdiv imgcontent';
        height: 100%;
        width: 100%;
        background-color: $primary_background;

        .title {
            grid-area: title;
            width: 100%;
            height: 50px;
            background-color: $primary;

            .logo {
                height: 30px;
                margin-left: 8%;
                margin-top: 1%;
            }
        }
        .imgdiv {
            grid-area: imgdiv;
            position: relative;
            display: flex;
            overflow: hidden;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            height: 100vh;
            background-color: grey;
            .shadow {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: black;
                opacity: $opacity_images;
            }
            .imgbackground {
                height: 100vh;
            }
        }
    }
}

$insideMarginLeft: 7%;

@media (max-width: 600px) {
    .centraldiv {
        display: flex;
        flex-direction: column;
        background-color: #f7f7f7;
        max-width: 100vh;
        height: 100vh;
        overflow: hidden;
    }
}
