@import '../base/settings';

.h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #092F57;
    margin-bottom: 8px;
    margin-top: 20px;
}
.h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #092F57;
}
.p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #4A4A4A;
}

a {
    color: #082f57;
}
.buttonStores {
    width: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #092F57;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.197492);
    border-radius: 5px;
    padding: 5px;
    margin-top: 15px !important;
    width: 100%;
    img {
        margin-right: 7px;
        margin-left: 7px;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.helpTextPopup {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #515151 !important;
    text-align: left;
}

.helperTextPopupBox {
    margin: 0px;
}