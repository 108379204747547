@import '../base/settings';

#helpTextPopupId {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #00a1da !important;
    line-height: 20px !important;
}

.validationIdentityButton {
    margin-top: 30px !important;
    margin-bottom: 20px !important;
    display: flex;
    justify-content: center;
    align-content: center;
}

.mainDiv {
    transition: 0.5s all;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas: 'leftSide rightSide';
    margin-left: $marginLeft;
    margin-top: $marginTop;
    .leftSide {
        grid-area: leftSide;
    }
    .rightSide {
        grid-area: rightSide;
        display: flex;
        flex-direction: column-reverse;
        .background {
            position: fixed;
            top: 5vh;
            width: 40vw;
        }
        .questions {
            height: 50%;
            width: 100%;
        }
    }
}

.documentSliderBackground {
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.6;
    height: 1200px;
    width: 100%;
    position: absolute;
    -webkit-animation: fadein 0.7s;
    -moz-animation: fadein 0.7s;
    -ms-animation: fadein 0.7s;
    -o-animation: fadein 0.7s;
    animation: fadein 0.7s;
}

.documentSlider {
    margin-top: 5%;
    width: 100%;
    height: 1px;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
}

.qrstyle {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 280px;
    text-align: center;
    padding-left: 5px;
    padding-right: 100px;
    margin-bottom: 200px;
    z-index: 50;
    margin-left: 10px;
}

.phone img {
    margin-bottom: 0px;
    padding-top: 0px;
    padding-left: 250px;
    z-index: 499;
}

.hint-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    align-content: flex-start;
    padding-left: 0;
}
.hint-list li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333333;
}
.hint-list img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

@media (max-width: 600px) {
    .hint-list {
        margin-top: 20px;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.6;
    }
}

// Firefox < 16
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.6;
    }
}

// Safari, Chrome and Opera > 12.1
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.6;
    }
}

//Internet Explorer
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.6;
    }
}
