@import '../../base/settings';
.background {
    z-index: -10;
    position: absolute;
    top: 20px;
    right: 0;
}

@media (min-width: 900px) {
    .mainDiv {
        transition: 0.5s all;
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-areas: 'leftSide rightSide';
        margin-left: $marginLeft;
        margin-top: $marginTop;
        .leftSide {
            grid-area: leftSide;
        }
        .rightSide {
            grid-area: rightSide;
            display: flex;
            flex-direction: column;
            .background {
                z-index: -9;
                margin-top: -20%;
                //position: absolute;
                width: 100%;
                //left: 70%;
                overflow: hidden;
            }
            .questions {
                height: 50%;
                width: 100%;
                .divider {
                    width: 80%;
                    height: 1px;
                    background-color: #6ec2fb;
                }
            }
        }
    }
}

@media (max-width: 900px) {
    * {
        transition: 0.3s all;
    }
    .macro-step-container{
        height: 100vh;
        align-items: flex-start;
    }
    .mainDiv {
        margin-top: 10%;
        display: flex;
        flex-direction: column;
        .leftSide {
            left: 0;
            padding-left: 5%;
            position: absolute;
            width: 100%;
            .stepper {
                width: 90%;
            }
        }
        .background {
            position: fixed;
            z-index: -99;
            margin-top: -50%;
            width: 100%;
            opacity: 0.4;
            overflow: hidden;
        }
        .questions {
            visibility: hidden;
        }
    }
    .endPage {
        pointer-events: none;
        position: absolute;
    }
}

@media (min-width: 1600px) {
    .mt-faq {
        margin-top: 25%;
    }
}

.mt-faq-upload-page{
    position: relative;
    margin: 0;
}